@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*,
body {
  font-family: "Poppins" !important;
}


h2 {
  font-weight: 600 !important;
  color: black;
}

h3 {
  font-weight: 600 !important;
}


h4 {
  font-weight: 600 !important;
}



/* Dashboard Layout.css */

.layout-container {
  display: flex;
  height: 100vh;
  /* background-color: #f4f4f4; */
  background-color: #1e6d8a12;
  flex-direction: column;
}

@media (min-width: 768px) {
  .layout-container {
    flex-direction: row;
    overflow: hidden;
  }
}

.sidebar-container {
  width: 100%;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .sidebar-container {
    width: 15rem;
  }
}

.content-container {
  flex-grow: 1;
  padding: 1.5rem;
  overflow-y: auto;
}

@media (min-width: 768px) {
  .content-container {
    padding: 0rem 1rem 2rem;
  }
}


.sidebar-container {
  overflow-y: auto;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.sidebar-container::-webkit-scrollbar {
  display: none !important;
}



.nav-item:hover {
  color: #1e6e8a !important;
}


.footer-section {
  background-color: white;
  color: black;
  border-top: 1px solid gainsboro;
}

.footer-div-title {
  width: 461px;
  height: 36px;
  top: 70px;
  left: 100px;
  color: black;
  font-size: 30px;
  margin-left: -4rem;
}

.footer-txt {
  font-size: 14px;
  font-weight: 400;
}



.otp-form {
  display: flex;
  flex-direction: row;
  gap: 3%;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  justify-content: center;
  margin: 13% 0;
}

.otp-form-txt {
  color: #616161;
  width: 90%;
  margin: auto;
  text-align: center;
  font-size: 14px;
}


.css-1gaup4j-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #1e6e8a !important;
}



@keyframes pulse {
  0% {
    transform: scale(.1);
    opacity: 0;
  }

  50% {
    opacity: .4;
  }

  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}


.loading {
  background-color: #1e6e8a;
  border-radius: 50%;
  position: absolute;
  margin: auto auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 70px;
  height: 70px;
}

.loading:nth-child(1) {
  animation: pulse 2s infinite;
}

.loading:nth-child(2) {
  animation: pulse 2s infinite .3s;
}

.loading:nth-child(3) {
  text-align: center;
  font-size: 14px;
  line-height: 40px;
  width: 30px;
  height: 30px;
}



.trust-badges-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 10px;
}

.trust-badge {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 10px 0;
}

.badge-icon {
  width: 65px;
  height: 65px;
  color: var(--main-color);
  margin-right: 20px;
}

.badge-text {
  max-width: 400px;
}

.badge-text h3 {
  margin: 0 0 10px 0;
  font-size: 1.2rem;
}

.badge-text p {
  margin: 0;
  font-size: 1rem;
}


.profile-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  border: 1px solid #E0E0E0;
}

.profile-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-pic {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  border: 2px dashed var(--main-color);
}

.profile-options {
  font-size: 24px;
  cursor: pointer;
}

.product-details-list {
  list-style: none; /* Removes default bullets */
  padding: 0;
  margin: 20px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  overflow: hidden;
}

.product-details-list li {
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #333;
}

.product-details-list li:last-child {
  border-bottom: none; 
}

.product-details-list li span {
  color: #1e6e8a; 
}

.product-details-list li:hover {
  background-color: #f5f5f5;
  transition: background-color 0.3s ease;
}


.platform-menu {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 10px;
  gap: 15px;
  z-index: 10;
  transition: all 0.3s ease-in-out;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 14px;
  transition: background 0.2s ease-in-out;
  white-space: nowrap;
}

.dropdown-item:hover {
  background: #f0f0f0;
}

.dropdown-icon {
  font-size: 18px;
  color: #1e6e8a;
}


.social-links {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  background: linear-gradient(180deg, #1e6e8a, #1e6e8a);
  border-radius: 0 10px 10px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.social-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 50%;
  color: #1e6e8a;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.social-link:hover {
  background-color: #0e5670;
  color: #ffffff;
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.blog-page {
  background: linear-gradient(to right, #F5FFF5, white);
}

.whatsapp:hover {
  background-color: #0e5670;
  color: #ffffff;
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}


@media (max-width: 768px) {
  .social-links {
    left: auto;
    right: 10px;
    top: auto;
    bottom: 10px; 
    transform: none;
    flex-direction: row; 
    gap: 10px;
    border-radius: 10px;
    padding: 5px;
  }

  .social-link {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 480px) {
  .social-links {
    gap: 5px; 
  }

  .social-link {
    width: 35px;
    height: 35px;
  }
}

.privacy-content p {
  margin: 0;
  padding: 0;
}

.privacy-content h2 {
  margin: 0;
  padding: 0;
}
